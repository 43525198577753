// Imports
import StickySidebar from 'sticky-sidebar-v2';

class PageStickySidebar {
    constructor() {
        const pageSide = document.getElementById('page-side');
        const headerEl = document.getElementById('mn-hdr');

        if (pageSide) {
            let pageSidebar = new StickySidebar(pageSide, {
                containerSelector: '.page-container',
                innerWrapperSelector: '.page-side-inner',
                topSpacing: headerEl ? headerEl.offsetHeight : 0,
                bottomSpacing: 0
            });
        }
    }
}

export default new PageStickySidebar();
