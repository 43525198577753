class Analytics {
    constructor() {
    }

    /**
     * Track page views
     *
     * @param {string} url - The page view URL
     * @param {string} title - The page title
     * @param {string} referrer - The referrer URL
     */
    trackPageView(url, title = null, referrer = null) {
        if (url) {
            // Parse the URL to extract parts from it
            const urlObject = new URL(url);

            // GA
            if (this.gaExists()) {
                gtag('event', 'page_view', {
                    'page_title': title,
                    'page_location': urlObject.href,
                    'page_path': urlObject.pathname,
                });
            }

            // Fathom
            if (this.fathomExists()) {
                fathom.trackPageview({
                    url: urlObject.href,
                    referrer: referrer
                });
            }
        }
    }

    /**
     * Track event
     *
     * GA tracking
     * @param {string} gaAction - The value that will appear as the event action in Google Analytics Event reports.
     * @param {string} gaCategory - The category of the event.
     * @param {string} gaLabel - The label of the event.
     * @param {number} gaValue - A non-negative integer that will appear as the event value.
     *
     * Fathom tracking
     * @param {string} fathomEventCode - The event code from Fathom
     * @param {int} fathomValue - The int value, e.g. 100 = $1.00
     */
    trackEvent({ gaAction, gaCategory = null, gaLabel = null, gaValue = 0 }, { fathomEventCode, fathomValue = 0 }){
        if (gaAction && this.gaExists()) {
            // GA
            gtag('event', gaAction, {
                'event_category': gaCategory,
                'event_label': gaLabel,
                'value': gaValue
            });
        }
        if (fathomEventCode && this.fathomExists()) {
            // Fathom
            fathom.trackGoal(fathomEventCode, fathomValue);
        }
    }

    /**
     * Test if GA exists
     *
     * @returns {boolean}
     */
    gaExists() {
        return typeof gtag !== 'undefined';
    }

    /**
     * Test if fathom exists
     *
     * @returns {boolean}
     */
    fathomExists() {
        return typeof fathom !== 'undefined';
    }
}

export default new Analytics();
