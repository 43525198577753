/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

// Alpine JS
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

// HTMX
import htmx from 'htmx.org';

// Project config
import Config from '@js/config/Config';

// Project utils
import utils from '@js/utils/utils';

// Project helpers
import VhCalc from '@js/helpers/ViewHeightCalc';
import HhCalc from '@js/helpers/HeaderHeightCalc';

// Project modules
import Analytics from '@js/modules/Analytics';
import CookieMessage from '@js/modules/CookieMessage';
import Copy from '@js/modules/Copy';
import EndlessScroll from '@js/modules/EndlessScroll';
import HeaderScroll from '@js/modules/HeaderScroll';
import Modal from '@js/modules/Modal';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageStickySidebar from '@js/modules/PageStickySidebar';
//import ScrollProgress from '@js/modules/ScrollProgress';
import ScrollTo from '@js/modules/ScrollTo';
import Story from '@js/modules/Story';
import Tooltips from '@js/modules/Tooltips';

// User modules
import CheckUsername from '@js/user/CheckUsername';
import PasswordStrength from '@js/user/PasswordStrength';
import UserSession from '@js/user/UserSession';
//import Theme from '@js/user/Theme';

// Discovery modules
import DiscoveryInsights from '@js/discovery/insights/DiscoveryInsights';
import DiscoveryRecommendRelated from '@js/discovery/recommend/DiscoveryRecommendRelated';

window.SHORTSTORIES =
    window.SHORTSTORIES ||
    new (function SHORTSTORIES() {
        // Config
        this.config = Config;

        // Utilities
        this.utils = {
            utils: utils,
        };

        this.modules = {
            PageScrollingManager: PageScrollingManager,
        };

        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Overrides the request class (default is `htmx-request`)
            htmx.config.requestClass = 'loading';

            htmx.on('htmx:afterSettle', function(event) {
                let targetId = event.target.id;

                console.log(targetId);

                // About page
                if (targetId === 'author-page') {
                    console.log(location.href);
                    // Set timeout to wait for HTMX to complete things
                    setTimeout(() => {
                        // Send page view to analytics
                        Analytics.trackPageView(location.href);
                        console.log(location.href);
                    }, 200);


                    // Reinit endless scroll
                    EndlessScroll.init();

                    // Reinit click tracking on elements
                    DiscoveryInsights.trackClicks();
                }
            });

            /**
             * Lazy load
             */
            // let lazyLoadInstance = new LazyLoad({
            //     threshold: 400
            // });

            /**
             * Discovery API
             */
            // Get related content
            DiscoveryRecommendRelated.init();

            /**
             * User session
             */
            UserSession.startPolling();


            let clearErrorsAttrs = 'data-clear-errors-focus';
            let clearErrorEls = document.querySelectorAll('['+clearErrorsAttrs+']');

            clearErrorEls.forEach((field) => {
                let errors = field.getAttribute(clearErrorsAttrs);

                field.addEventListener('click', (e) => {
                    let errorsEl = document.getElementById(errors);

                    if (errorsEl) {
                        errorsEl.remove();
                    }
                });
            });

            const memberPhotoEl = document.getElementById('member-photo');
            if (memberPhotoEl) {
                memberPhotoEl.addEventListener('change', (e) => {
                    e.preventDefault();

                    const form = document.querySelector('.account-form');
                    const submitBtn = form.querySelector('[type="submit"]');

                    if (submitBtn) {
                        submitBtn.setAttribute('disabled', 'disabled');
                    }

                    form.submit();

                    // let formData = new FormData(accountPhotoForm);
                    // const fileInput = document.getElementById('member-photo');
                    // const file = fileInput.files[0]; // Get the file from the file input field
                    //
                    // // Append the file to the FormData object
                    // formData.append('memberProfile', file);
                    // console.log({formData});
                    //
                    // fetch('/actions/businesslogic/memberphoto/update', {
                    //     method: 'POST',
                    //     body: formData,
                    //     headers: {
                    //         'Accept': 'application/json'
                    //     },
                    // })
                    //     .then(response => response.json())
                    //     .then(data => {
                    //         console.log({data});
                    //         if (data.success) {
                    //             // Handle success
                    //         } else {
                    //             // Handle error
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.error('Error:', error);
                    //     });
                });
            }


            /*
             * Login form
             */
            // let loginForm = document.getElementById('login-form');
            // if (loginForm) {
            //     loginForm.addEventListener('submit', (e) => {
            //         e.preventDefault();
            //
            //         // //let serializeForm = serialize(e.target, { hash: true });
            //         //
            //         // const params = new FormData();
            //         // params.append('loginName', e.target.getElementById('login-name'));
            //         // params.append('password', e.target.getElementById('password'));
            //         //
            //         // console.log(serializeForm);
            //
            //         const params = new FormData(e.target);
            //
            //         fetch('/', {
            //             method: 'POST',
            //             body: params,
            //             headers: {
            //                 'Accept': 'application/json',
            //                 'X-Requested-With': 'XMLHttpRequest',
            //             },
            //         })
            //             .then(response => response.json())
            //             .then((data) => {
            //                 console.log(data);
            //
            //                 // If successful login redirect the user
            //                 if (data.success) {
            //                     // window.location.href = returnUrl;
            //                 }
            //             })
            //             .catch((error) => {
            //                 console.warn(error);
            //             });
            //     });
            // }

            /*
             * Signup form
             */
            // let signupFormEls = document.querySelectorAll('.signup-form');
            // signupFormEls.forEach((signupForm) => {
            //     signupForm.addEventListener('submit', (e) => {
            //         e.preventDefault();
            //
            //         const params = new FormData(e.target);
            //
            //         fetch('/', {
            //             method: 'POST',
            //             body: params,
            //             headers: {
            //                 'Accept': 'application/json',
            //                 'X-Requested-With': 'XMLHttpRequest',
            //             },
            //         })
            //             .then(response => response.json())
            //             .then((data) => {
            //                 console.log(data);
            //
            //                 // If successful login redirect the user
            //                 if (data.success) {
            //                     // window.location.href = returnUrl;
            //                 }
            //             })
            //             .catch((error) => {
            //                 console.warn(error);
            //             });
            //     });
            // });

            /**
             * Story audio player
             */
            const storyContent = document.querySelector('.story-content');
            if (storyContent) {
                const storyPlayerWidget = null;
                const storyProfileSlab = document.querySelector('.story-profile-slab');
                const storyPlayerEl = document.querySelector('.story-player');
                const storyPlayerBtn = document.querySelector('.story-player-btn');

                if (storyProfileSlab) {
                    import(
                        "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs"
                        ).then(async (speechifyWidget) => {
                        // Widget configuration
                        const config = {
                            // This is the root listenable element that the Speechify API will traverse
                            rootElement: storyContent,
                            // This is the header of your article; the inline player will be placed under this heading
                            inlinePlayerElement: storyProfileSlab,
                            // All custom visibility options go in this object
                            visibility: {
                                showInlinePlayer: false,
                                showInlinePlayerOnPlay: false,
                                showWidget: false,
                                showWidgetOnPlay: true,
                            },
                            // Styles - Include a CSS-in-JS style object here
                            customStyles: {
                                // Each property key is the name of a Speechify API element
                                playButton: {
                                    color: '#48bb78',
                                },
                                inlinePlayerPlayButton: {
                                    color: '#48bb78',
                                },
                                seekbar: {
                                    backgroundColor: 'rgba(72, 187, 120, .7)',
                                },
                                seekbarThumb: {
                                    backgroundColor: '#48bb78',
                                }
                            },
                        };

                        const storyPlayerWidget = speechifyWidget.makeSpeechifyExperience(config);
                        await storyPlayerWidget.mount();

                        // Show player button
                        if (storyPlayerEl) {
                            storyPlayerEl.classList.remove('hidden');

                            storyPlayerBtn.addEventListener('click', e => {
                                e.preventDefault();
                                storyPlayerWidget.play();
                                storyPlayerEl.remove();
                            });
                        }
                    });
                }
            }
        });
    })();
