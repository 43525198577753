// Define the attribute name as a constant
const ATTR = 'data-author-more-stories';

class Story {
    constructor() {
        // Find the element with the specified attribute
        const moreEl = document.querySelector(`[${ATTR}]`);

        // Check if the element is found
        if (moreEl) {
            this.loadMoreContent(moreEl);
        }
    }

    async loadMoreContent(element) {
        try {
            // Get the ID and current URL using the constant
            const id = element.getAttribute(ATTR);
            const currentUrl = window.location.href;

            // Fetch additional content
            const response = await fetch(`${currentUrl}/more/${id}`);

            // Check if the request was successful
            if (response.ok) {
                // Parse the response text as HTML content
                const htmlContent = await response.text();

                // Create a new element to contain the fetched HTML content
                const container = document.createElement('div');
                container.innerHTML = htmlContent;

                // Append the fetched content to the specified element
                element.appendChild(container);
            } else {
                // Handle non-OK responses (e.g., 404 Not Found)
                console.error('Error fetching content:', response.status);
            }
        } catch (error) {
            // Handle fetch errors
            console.error('Error fetching content:', error);
        }
    }
}

// Export the Story class
export default new Story;
