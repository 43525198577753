/* Project imports */
import Toast from '@js/modules/Toast';

class CookieMessage {
    constructor() {
        this.lsItemName = '_cookie-message';

        const lsCookieMessage = localStorage.getItem(this.lsItemName);

        if (!lsCookieMessage) {
            // Make an AJAX request to get data from the API
            fetch(import.meta.env.VITE_PRIMARY_SITE_URL + '/api/v1/cookie-message.json')
                .then(response => response.json())
                .then(data => {
                    const { show, message } = data;

                    if (show === true || show === 1) {
                        Toast.success(message, {
                            appearDelay: 500,
                            timeout: -1,
                            onShow: () => {
                                localStorage.setItem(this.lsItemName, 1);
                            }
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }
}

export default new CookieMessage();
